import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Form,
  Dropdown,
} from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import CheckBox from "../../components/CheckBox";
import RadioBtn from "../../components/RadioBtn";
import Select from "react-select";
import VerifyPwWithUser from "../../components/Modals/VerifyPwWithUser";
import list_wounds from "../../functions/list_wounds";
import api from "../../services/api";
import moment from "moment";
import ModalUnlinkSensor from "./ModalUnlinkSensor";
import { Show_Alert } from "../../store/actions/alert";
import {
  GetTagName,
  ConvertRuntime,
  adjustSeconds,
  userWithoutPermission,
  userHasPermission,
} from "../../functions/utils";

var originalUsers = null;
var timer = null;

var type_time = [
  {
    label: "horas",
    value: "hours",
  },
  {
    label: "minutos",
    value: "minutes",
  },
  // {
  //   label: "dias",
  //   value: "days",
  // },
];

function ModalMdd({ ...props }) {
  const {
    handler_modal,
    show_modal,
    data,
    beacon: beaconProp,
    handler_reload,
    reload,
    handler_loading,
    position_actual,
    position_to,
    patient_id,
    notification_id,
    patient_name,
    room,
    subroom,
    tag,
    beacon_time,
    tag_color,
    suspended,
    history,
    suspendedFlag,
    isProgressive,
    handlerWaitMddLoad,
    timeProgressive,
    defaultTimeProgressive,
  } = props;

  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);

  const [showModalMdd, setShowModalMdd] = useState(show_modal);
  const [beacon, setBeacon] = useState(beaconProp);
  const [position_actual_local, setPosition_actual_local] =
    useState(position_actual);
  const [position_to_local, setPosition_to_local] = useState(position_to);
  const [beacon_time_local, setBeacon_time_local] = useState(beacon_time);
  const [tag_local, setTag_local] = useState(tag);
  const [tag_color_local, setTag_color_local] = useState(tag_color);
  const [positions, setPositions] = useState([]);
  const [targetPosition, setTargetPosition] = useState(null);
  const [showEditPosition, setShowEditPosition] = useState(false);
  const [mainAngle, setMainAngle] = useState(beaconProp?.main_angle || 0);
  const [secondAngle, setSecondAngle] = useState(beaconProp?.second_angle || 0);

  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showRenderPage1, setShowRenderPage1] = useState(true);

  const itemsWithPause = [
    "Exames",
    "Centro cirúrgico",
    "Em procedimento",
    "Não está no leito",
    "Hemodiálise",
  ];

  const unlinkSensor = (userId) => {
    setLoadingBtn("flex");
    api
      .put(`/patients/${patient_id}`, {
        beacon_id: 0,
        user_id: userId,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Sensor desvinculado com sucesso",
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível desvincular o sensor. Tente novamente mais tarde",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
        handleCloseUnlinkModal();
      });
  };
  const handleOpenUnlinkModal = () => {
    setShowModalMdd(false);
    setShowUnlinkModal(true);
  };

  const handleCloseUnlinkModal = () => {
    setShowUnlinkModal(false);
    setShowModalMdd(true);
  };

  function ConvertToMinutes(unit, time) {
    let minutes = 0;
    if (unit === "hours") {
      minutes = parseInt(time) * 60; // Correção: Multiplicando corretamente horas por 60
    } else if (unit === "minutes") {
      minutes = parseInt(time);
    }
    return minutes;
  }

  // Página do modal
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [data, show_modal]);

  const handleMotiveSelection = (item, isPaused) => {
    setMotiveChooser(item);
    setIsPaused(isPaused);
    if (
      item.name !== "Outros" ||
      (item.name === "Outros" && otherMotive !== "")
    ) {
      setIsMotiveSelected(true);
      if (
        !itemsWithPause.includes(item.name) &&
        item.name !== "Outros" &&
        !defaultSuspensionTimeIsEnable &&
        isPaused !== undefined
      ) {
        setTimeSuspend(null);
        setPage(6);
      }
    }
  };

  const handleTimeSelection = (time) => {
    setTimeSuspend(time);
    setTypeTimeSuspend({ label: "minutos", value: "minutes" });
  };

  const [showPw, setShowPw] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [isMotiveSelected, setIsMotiveSelected] = useState(false);
  const [isPaused, setIsPaused] = useState(null);

  // Dados selecionados
  const [search, setSearch] = useState("");
  const [responsavel, setResponsavel] = useState([]);
  const [motivo, setMotivo] = useState(null);
  const [lesoes, setLesoes] = useState(null);
  const [hoverItem, setHoverItem] = useState(null);

  const [motiveChooser, setMotiveChooser] = useState({ id: 0 });
  const [listUserChooser, setListUserChooser] = useState([]);
  const [otherMotive, setOtherMotive] = useState("");
  const [otherMotiveInvalid, setOtherMotiveInvalid] = useState(false);

  const [listMotives, setListMotives] = useState([]);
  const [listUsers, setListUsers] = useState([]);

  const [hasWound, setHasWound] = useState(null);
  const [wounds, setWounds] = useState([{ wound_id: 0, stage_id: 0 }]);
  const [listWounds, setListWounds] = useState(list_wounds);
  const [listStages, setListStages] = useState([]);
  const [timeSuspend, setTimeSuspend] = useState(null);

  const [typeTimeSuspend, setTypeTimeSuspend] = useState({
    label: "horas",
    value: "hours",
  });
  const [suspendForever, setSuspendForever] = useState(false);
  const [suspendIndeterminate, setSuspendIndeterminate] = useState(false);
  const [info, setInfo] = useState({});
  const [nextDatetime, setNextDatetime] = useState("");
  const [updateTime, setUpdateTime] = useState(false);
  const [suspendTime, setSuspendTime] = useState(
    moment.utc(suspended?.time_suspend_left * 1000).format("HH:mm:ss")
  );
  const [isSuspendeded, setIsSuspended] = useState(
    Object.keys(suspended).length > 0 || tag == "suspend"
  );

  const [suspensionTimeVisible, setSuspensionTimeVisible] = useState(false);

  const toggleSuspensionTime = () => {
    setSuspensionTimeVisible(!suspensionTimeVisible);
  };

  const motiveTimes = {
    "Decúbito contraindicado": [30, 60, 90, 120],
    "Em procedimento": [30, 60, 90, 120],
    Hemodiálise: [60, 120, 180, 240],
    "Horário de banho": [30, 60, 90],
    "Instabilidade hemodinâmica": [60, 120, 180, 240, 360],
    "Instabilidade ventilatória": [60, 120, 180, 240],
    "Não está no leito": [30, 60, 90, 120],
    "Paciente agitado": [60, 90, 120, 180],
    "Paciente consciente": [30, 60, 90, 120],
    "Recusou a mudança": [60, 90, 120],
    "Sentado em poltrona": [30, 60, 90, 120],
    Outros: [30, 60, 120, 180],
  };

  const [defaultSuspensionTimeIsEnable, setDefaultSuspensionTimeIsEnable] =
    useState(false);

  /*©
  page 1 = default
  page 2 = Sim
  page 3 = Não
  page 4 = Suspender avisos
  */

  function checkIfUserHasPermissionAndSuspend() {
    if (userHasPermission(user)) {
      Suspend();
      return true;
    } else {
      setShowPw(true);
    }
  }

  useEffect(() => {
    function LoadInfo() {
      api
        .get(`/notifications/${notification_id}/beacons/info`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadInfo success");
            console.log(response.data);
            setInfo(response.data);
            setNextDatetime(
              isProgressive
                ? moment(timeProgressive, "HH:mm:ss")
                    .subtract(1, "second")
                    .format("HH:mm:ss")
                : response.data.next_time_position
            );
          }
        })
        .catch((error) => {
          console.log("LoadInfo  error" + error);
        });
    }

    function LoadPositions() {
      api.get("/positions").then((response) => {
        if (response.status === 200) {
          setPositions(response.data);
        }
      });
    }

    LoadInfo();
    LoadPositions();
  }, []);

  useEffect(() => {
    setNextDatetime(adjustSeconds(nextDatetime, 1, "subtract"));
    setSuspendTime(adjustSeconds(suspendTime, 1, "subtract"));
    setBeacon_time_local(adjustSeconds(beacon_time_local, 1, "add"));

    setTimeout(() => {
      setUpdateTime(!updateTime);
    }, 1000);
  }, [updateTime]);

  useEffect(() => {
    function LoadStages() {
      api
        .get(`/stages`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadStages success");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListStages(array);
          }
        })
        .catch((error) => {
          console.log("LoadPatient " + error);
        });
    }

    LoadStages();
  }, []);

  useEffect(() => {
    function LoadMotives() {
      let endpoint = page == 5 ? "motive_discharge_hospitals" : "motives";
      api
        .get(`/${endpoint}`, {
          params: {
            is_suspend: 0,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadMotives success");
            console.log(response.data);
            setListMotives(response.data);
          }
        })
        .catch((error) => console.log("LoadMotives " + error));
    }

    LoadMotives();
  }, [page]);

  useEffect(() => {
    function LoadDefaultSuspensionTime() {
      api
        .get("/default-settings/hospitals/suspension-time")
        .then((response) => {
          if (response.status == 200) {
            setDefaultSuspensionTimeIsEnable(response.data.is_enable);
            if (response.data.is_enable) {
              setTimeSuspend(response.data.minutes);
              setTypeTimeSuspend({
                label: "minutos",
                value: "minutes",
              });
            }
          }
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            console.log("LoadDefaultSuspensionTime " + error);
          }
        });
    }

    if (page === 1 || page === 4) {
      LoadDefaultSuspensionTime();
    }
  }, [page]);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: unit.selecionado.id,
            name: search,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            originalUsers = response.data;
            setListUsers(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        LoadUsers();
      }, 500);
    }
  }, [page, search]);

  useEffect(() => {
    setListUserChooser([]);
    setMotiveChooser({ id: 0 });
    setOtherMotive("");
    setHasWound(null);
    setWounds([{ wound_id: 0, stage_id: 0 }]);
  }, [show_modal]);

  function RenderTooltip() {
    if (!beacon.angle_corrected && beacon.signal_beacon) {
      return (
        <Tooltip id="tooltip-top">
          <div>
            <p>Paciente fora da angulação de 20° a 30°</p>
          </div>
        </Tooltip>
      );
    } else {
      return <div />;
    }
  }

  function renderUnlinkTooltip(props) {
    return (
      <Tooltip id="" {...props}>
        Desvincular
      </Tooltip>
    );
  }

  function RenderTooltipEdit(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  function SetarUser(obj) {
    var found = listUserChooser.find((item) => item.id == obj.id);
    if (found != undefined) {
      var newList = listUserChooser.filter((item) => item.id != obj.id);
      setListUserChooser(newList);
    } else {
      if (listUserChooser.length == 4) return false;
      if (listUserChooser.length == 1 && page == 4) return false;
      setListUserChooser([...listUserChooser, obj]);
    }
  }

  function VerifyFields() {
    var ret = false;

    if (page == 2) {
      //Sim
      if (listUserChooser.length > 0) ret = true;
    } else if (page == 3) {
      //Não
      if (listUserChooser.length > 0 && motiveChooser.id > 0) ret = true;
    } else if (page == 4 || page == 5) {
      //Suspender aviso
      if (
        motiveChooser.id > 0
        // && hasWound != null
      ) {
        ret = true;
      }

      // if (user.dados.permission_id > 3) {
      //   if (listUserChooser.length > 0) {
      //     ret = true;
      //   } else {
      //     ret = false;
      //   }
      // }
      if (!suspendForever) {
        if (timeSuspend == "") {
          ret = false;
        }
      }

      if (!suspendIndeterminate) {
        if (timeSuspend === "INDETERMINATE") {
          ret = false;
        }
      }

      // if (hasWound) {
      //   wounds.map((item) => {
      //     if (item.wound_id == 0 || item.stage_id == 0) ret = false;
      //   });
      // }
    }

    if (motiveChooser?.name == "Outros") {
      if (otherMotive == "") ret = false;
      if (
        otherMotive.match(
          /^([!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/~`-]|.)\1*$|^.{0,1}$/g
        )
      ) {
        ret = false;
      }
    }
    return ret;
  }

  // Submits
  function Confirm(user_id) {
    setLoadingBtn("flex");
    setShowPw(false);
    // var arrayNurses = [];
    // listUserChooser.map((item) => {
    //   arrayNurses.push({ id: item.id });
    // });

    // console.log("done " + page == 2 ? true : false);
    // console.log("unit_id " + unit.selecionado.id);
    // console.log("datetime_realized " + moment().format("YYYY-MM-DD HH:mm:ss"));
    // console.log("nurses ");
    // console.log(arrayNurses);
    // console.log("motives");
    // var m = {
    //   id: motiveChooser.id > 0 ? motiveChooser.id : "",
    //   other: otherMotive,
    //   is_suspend: "false",
    // };
    // console.log(m);
    // console.log("notification_id " + dados.notification_id);

    api
      .put(`/notifications/${notification_id}`, {
        done: page == 2 ? true : false,
        unit_id: unit.selecionado.id,
        datetime_realized: moment().format("YYYY-MM-DD HH:mm:ss"),
        nurses: [{ id: user_id == undefined ? user.dados.id : user_id }],
        motives: {
          id: motiveChooser.id > 0 ? motiveChooser.id : "",
          other: otherMotive,
          is_suspend: "false",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Suspend success");
          console.log(response.data);
          var msg = "";

          if (page == 2) {
            msg = "A mudança de decúbito foi confirmada  com sucesso";
          } else if (page == 3) {
            msg = "Informado com sucesso";
          }

          dispatch(
            Show_Alert({
              type: "success",
              msg,
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
        handler_modal(false);
      });
  }

  function Suspend(user_id) {
    setLoadingBtn("flex");
    setShowPw(false);
    // var arrayNurses = [];
    // listUserChooser.map((item) => {
    //   arrayNurses.push({ id: item.id });
    // });

    var id = user_id;
    if (user_id == undefined) id = user.dados.id;
    // if (arrayNurses.length > 0) user_id = arrayNurses[0].id;
    // else user_id = user.dados.id;
    handler_modal(false);
    handler_loading(true);
    handlerWaitMddLoad(true);
    api
      .put(`/patients/${patient_id}/suspend_notifications/${notification_id}`, {
        user_id: id,
        motive_id: motiveChooser.id,
        other_motive: otherMotive,
        is_paused: isPaused ? isPaused : false,
        //is_wound: hasWound,
        wounds,
        time_minutes: suspendForever
          ? 0
          : suspendIndeterminate
          ? "INDETERMINATE"
          : ConvertToMinutes(typeTimeSuspend.value, timeSuspend),
      })
      .then((response) => {
        if (response.status == 204) {
          console.log("Suspend success");
          console.log(response.data);
          handlerWaitMddLoad(false);
          handler_reload(!reload);
          if (suspendForever) {
            dispatch(
              Show_Alert({
                type: "success",
                msg: "A alta foi informada com sucesso",
              })
            );
          } else if (suspendIndeterminate) {
            dispatch(
              Show_Alert({
                type: "success",
                msg: "Avisos suspensos por tempo indeterminado",
              })
            );
          } else {
            dispatch(
              Show_Alert({
                type: "success",
                msg: `Os avisos foram ${
                  isPaused ? "pausados" : "suspensos"
                } com sucesso`,
              })
            );
          }
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
        handler_loading(false);
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
      });
  }

  function Reactivate(userId) {
    var id = userId;
    if (userId == undefined) {
      id = user.dados.id;
    }
    handler_modal(false);
    handler_loading(true);
    api
      .get(`/patients/${patient_id}/reactivate_notifications`, {
        params: { userId: id },
      })
      .then((response) => {
        if (response.status == 200) {
          handlerWaitMddLoad(false);
          handler_reload(!reload);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Avisos reativados",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        handler_reload(!reload);
        ResetFields();
        setLoadingBtn("none");
      });
  }

  function ResetFields() {
    setMotiveChooser({ id: 0 });
    setListUserChooser([]);
    setOtherMotive("");
    setHasWound(null);
    setWounds({ wound_id: 0, stage_id: 0 });
  }

  // console.log("BEACON_TIME " + nextDatetime);

  function CompareTime(time, type) {
    if (time == null || time == undefined) return false;

    var parts = time.split(":");
    var hh = parts[0];
    var mm = parts[1];
    var ss = parts[2];
    var color = "#666666";
    if (isProgressive && type == "time_in_position") {
      if (hh >= defaultTimeProgressive) {
        color = "rgb(255, 123, 73)";
      }
    } else if (type == "time_in_position") {
      //Time in position (>= 2 orange)
      if (hh >= 2) {
        color = "rgb(255, 123, 73)";
      }
    } else {
      //Next datetime (<= 0 orange)
      if (hh < 0) {
        color = "rgb(255, 123, 73)";
      }
    }
    return color;
  }

  function RenderTimeInPosition() {
    if (!beacon.signal_beacon) return "-";
    if (beacon_time_local == "Data inválida") return "-";
    if (
      beacon_time_local == "" ||
      beacon_time_local == " " ||
      beacon_time_local == null
    )
      return "00:00:00";
    return beacon_time_local;
  }

  function RenderNextDatetime() {
    if (!beacon.signal_beacon) return "-";
    if (nextDatetime == "Data inválida" || nextDatetime == "Invalid date")
      return "-";
    return nextDatetime;
  }

  function RenderTimeSuspend() {
    if (!suspended.time_suspend_left) {
      return "-";
    }
    if (suspended.time_suspend_left <= 0) {
      return "00:00:00";
    }
    return suspendTime;
  }

  function RenderStylePosition(isIcon, isActualPosition) {
    var color = beacon.position_color;
    if (suspended?.is_paused) {
      if (isIcon) {
        color = Colors.brand_blue;
      }
      if (isActualPosition) {
        color = "#666666";
      }
    } else {
      if (!isIcon && !isActualPosition) {
        color = "#666666";
      } else {
        if (!beacon.signal_beacon) {
          color = Colors.new_orange;
        }
        if (isIcon && color == "#666666") color = Colors.brand_blue;
      }
    }
    return color;
  }

  function RenderIconPosition() {
    var icon = "beds";
    if (
      !suspended?.is_paused &&
      (!beacon.signal_beacon || !beacon.position_corrected)
    ) {
      icon = "warning";
    }
    return icon;
  }

  function GetColorTag() {
    if (tag_local == "suspend" || suspendedFlag) return "#999999";
    if (tag_local == "realized" && tag_color_local == "#FF7B49")
      return "#FF7B49";
    var color = tag_color_local;
    if (tag_local == "realized") color = "#999999";
    if (tag_local == "realized" && !beacon.angle_corrected)
      color = Colors.new_orange;
    return color;
  }

  function VerifyPosition() {
    var ret = true;
    if (tag_local == "realized") {
      ret = false;

      if (tag_color_local == "#FF7B49") {
        return true;
      }
    }
    return ret;
  }

  const renderPhoto = (user, size = null) => {
    if (user.dados.user_image != null)
      return (
        <img
          src={user.dados.user_image}
          className={`c-profile-dropdown__img ${
            size && size === "lg" ? "is-lg" : ""
          }`.trim()}
        />
      );

    let name;

    if (user.dados.user_name.split(" ").length > 1) {
      name = user.dados.user_name
        .split(" ")
        .reduce((p, c) => `${p.charAt(0)}${c.charAt(0)}`);
    } else {
      name = user.dados.user_name.substring(0, 2).toUpperCase();
    }

    return (
      <span
        className={`c-profile-dropdown__initial ${
          size && size === "lg" ? "is-lg" : ""
        }`.trim()}
      >
        {name}
      </span>
    );
  };

  function EditPosition(userId) {
    setLoadingBtn("flex");
    api
      .post("beacons/position-reference", {
        patientId: patient_id,
        beaconId: beacon.id,
        positionSource: beacon.raw_position_name,
        positionTarget: targetPosition,
        userId: userId,
      })
      .then((response) => {
        if (response.status === 200) {
          setLoadingBtn("none");
          handler_reload(!reload);
          handler_modal(false);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Posição editada com sucesso!",
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          handler_reload(!reload);
          handler_modal(false);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi atribuir a posição, pois a posição alvo possui seus valores zerados.",
            })
          );
        }
      });
  }

  function handleOtherMotive(motive) {
    if (
      motive.match(/^([!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/~`-]|.)\1*$|^.{0,1}$/g)
    ) {
      setOtherMotiveInvalid(true);
    } else {
      setOtherMotiveInvalid(false);
    }
    setOtherMotive(motive);
  }

  const handleMouseDown = (additionalParam) => (e) => {
    let url = "/Pacientes/Perfil-Paciente";
    console.log(patient_id);
    if (additionalParam === "reports") {
      dispatch({ type: "SET_PATIENT_TAB", selected: 8 });
    } else {
      dispatch({ type: "RESET_PATIENT_TAB" });
    }
    dispatch({
      type: "SET_PATIENT_SELECTED",
      dados: { id: patient_id, name: patient_name },
    });
    if (e.button === 0) {
      history.push({
        pathname: url,
      });
    } else if (e.button === 1) {
      window.open(url, "_blank");
    }
  };

  const redirectReportPage = () => {
    dispatch({
      type: "SET_PATIENT_SELECTED",
      dados: { id: patient_id, name: patient_name },
    });
    window.open("/Pacientes/Perfil-Paciente?tab=8", "_blank");
  };

  function RenderPage1() {
    return (
      <>
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div
              className="modal-mdd-tag"
              style={{
                backgroundColor: GetColorTag(),
              }}
            >
              <p>
                {GetTagName(
                  tag_local,
                  tag_color_local,
                  suspended,
                  isProgressive
                )}
              </p>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => handler_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <div className="my-2">
              <div className="d-flex">
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  className="modal-title-sm"
                  onMouseDown={handleMouseDown(null)}
                >
                  {patient_name}
                </span>
                <span className="d-flex align-items-center">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Relatórios do paciente</Tooltip>}
                  >
                    <span
                      className="ball-sensor"
                      variant="link"
                      onMouseDown={handleMouseDown("reports")}
                    >
                      <Icon
                        color={Colors.darkest_gray}
                        size={16}
                        icon="pizza-chart"
                        style={{ alignItems: "center" }}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>

              <div>
                <div
                  className="modal-text-sm mb-4"
                  style={{ color: Colors.dark_gray }}
                >
                  {room == "" || room == undefined
                    ? subroom
                    : room + " | " + subroom}
                </div>
              </div>

              <div className="modal-mdd-info-top modal-mdd-info-time">
                <div className="modal-mdd-info">
                  <p className="modal-mdd-info-title">Tempo na posição</p>
                  <div className="d-flex align-items-center">
                    {!suspended.is_paused ? (
                      <p
                        className="modal-mdd-info-text"
                        style={{
                          color: CompareTime(
                            beacon_time_local,
                            "time_in_position"
                          ),
                        }}
                      >
                        {RenderTimeInPosition()}
                      </p>
                    ) : (
                      <p
                        style={{
                          color: "#999999",
                        }}
                      >
                        -- : -- : --
                      </p>
                    )}
                  </div>
                </div>
                {(!isProgressive || (isProgressive && tag !== "delayed")) && (
                  <div className="modal-mdd-info">
                    <p className="modal-mdd-info-title">Próxima mudança</p>
                    <div className="d-flex align-items-center">
                      {!suspended.is_paused ? (
                        <p
                          className="modal-mdd-info-text"
                          style={{
                            color: CompareTime(nextDatetime, "next_datetime"),
                          }}
                        >
                          {RenderNextDatetime()}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "#999999",
                          }}
                        >
                          -- : -- : --
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {suspended.is_paused == true && (
                <div
                  className="d-flex"
                  style={{
                    backgroundColor: "#F5F6F9",
                    padding: "12px 16px",
                    marginBottom: "12px",
                    borderRadius: "10px",
                  }}
                >
                  <Icon icon="no-time" size={24} className="mr-3" />
                  <p>Monitoramento do tempo pausado</p>
                </div>
              )}

              <div className="modal-mdd-container-info-left d-flex flex-wrap justify-content-between align-items-center">
                <div className="modal-mdd-info-top">
                  <div className="modal-mdd-info">
                    <p className="modal-mdd-info-title">Posição atual</p>
                    <div className="d-flex align-items-center">
                      <Icon
                        color={
                          RenderStylePosition(true, false)
                          // beacon.signal_beacon || beacon.position_corrected
                          //   ? Colors.brand_blue
                          //   : Colors.new_orange
                        }
                        size={20}
                        icon={
                          RenderIconPosition()
                          // beacon.signal_beacon || beacon.position_corrected
                          //   ? "beds"
                          //   : "warning"
                        }
                      />
                      <p
                        className="modal-mdd-info-text"
                        style={{
                          color: RenderStylePosition(false, true),
                          // beacon.signal_beacon || beacon.position_corrected
                          //   ? Colors.darkest_gray
                          //   : Colors.new_orange,
                        }}
                      >
                        {beacon.signal_beacon
                          ? position_actual_local
                          : "SEM SINAL"}
                      </p>
                      {false && (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 10 }}
                          overlay={RenderTooltipEdit("Editar")}
                        >
                          <button
                            className="btn-edit-position ml-1"
                            onClick={() => {
                              setShowEditPosition(true);
                            }}
                          >
                            <Icon
                              color={Colors.darker_gray}
                              size={18}
                              icon="edit"
                            />
                          </button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                  {!suspended?.is_paused &&
                    ((isProgressive && position_actual_local != position_to) ||
                      (position_actual_local != position_to &&
                        VerifyPosition() &&
                        beacon.signal_beacon)) && (
                      <>
                        <Icon
                          color={Colors.brand_gray}
                          size={20}
                          icon="arrow-right-1"
                        />

                        <div
                          className="modal-mdd-info"
                          style={{ minWidth: 127 }}
                        >
                          <p className="modal-mdd-info-title">Mudar para</p>
                          <div className="d-flex align-items-center">
                            <Icon
                              color={Colors.brand_blue}
                              size={20}
                              icon="refresh"
                            />
                            <p
                              className="modal-mdd-info-text"
                              style={{
                                color: RenderStylePosition(false, false),
                                // beacon.signal_beacon || beacon.position_corrected
                                //   ? Colors.darkest_gray
                                //   : Colors.new_orange,
                              }}
                            >
                              {position_to}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                </div>
                {showEditPosition && (
                  <div className="modal-mdd-edit">
                    <span className="modal-title-sm mb-2 d-block">
                      Alterar a posição de decúbito
                    </span>
                    <p className="mb-2">Posição do paciente</p>
                    {positions.map((item, index) => {
                      if (item.name_beacon === beacon.raw_position_name) {
                        return;
                      }
                      return (
                        <button
                          style={{
                            background:
                              targetPosition === item.name_beacon
                                ? Colors.brand_blue
                                : Colors.lightest_gray,
                          }}
                          className="btn-click"
                          onClick={() => {
                            setTargetPosition(item.name_beacon);
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              color:
                                targetPosition === item.name_beacon
                                  ? "white"
                                  : Colors.darker_gray,
                            }}
                          >
                            {item.name}
                          </p>
                        </button>
                      );
                    })}
                    <div className="d-flex justify-content-end align-items-center">
                      <Col
                        xs={4}
                        md={3}
                        lg={3}
                        className="px-0 d-flex justify-content-end"
                      >
                        <button
                          className="mr-2 btn-cancel"
                          onClick={() => {
                            setShowEditPosition(false);
                          }}
                        >
                          Cancelar
                        </button>
                      </Col>
                      <Col xs={4} md={3} lg={3} className="px-0">
                        <button
                          disabled={
                            (targetPosition !== null ? false : true) ||
                            loadingBtn === "flex"
                          }
                          className="mb-0 modal-footer-btn btn-confirm"
                          style={{
                            backgroundColor:
                              targetPosition !== null
                                ? Colors.brand_pink
                                : Colors.lighter_gray,
                          }}
                          onClick={() => {
                            if (userHasPermission(user)) {
                              EditPosition();
                              return false;
                            }
                            setShowPw(true);
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              color:
                                targetPosition !== null
                                  ? "white"
                                  : Colors.dark_gray,
                              display: loadingBtn == "none" ? "flex" : "none",
                            }}
                          >
                            Salvar
                          </p>

                          <Spinner
                            style={{ display: loadingBtn }}
                            animation="border"
                            role="status"
                            variant="light"
                            size="sm"
                          />
                        </button>
                      </Col>
                    </div>
                    {showPw && (
                      <VerifyPwWithUser
                        handler_show_pw={setShowPw}
                        show_pw={showPw}
                        handler_request={EditPosition}
                      />
                    )}
                  </div>
                )}

                <div
                  className="modal-mdd-info-bottom"
                  style={{
                    alignItems: "normal",
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 10 }}
                    overlay={RenderTooltip()}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className="modal-mdd-info"
                    >
                      <p className="modal-mdd-info-title mb-1">
                        Angulação do paciente
                      </p>

                      <div className="d-flex">
                        {beacon.signal_beacon ? (
                          <div>
                            <div className="d-flex">
                              {beacon.signal_beacon && (
                                <Icon
                                  className="mr-2"
                                  color={
                                    beacon.angle_corrected
                                      ? Colors.brand_blue
                                      : Colors.new_orange
                                  }
                                  size={20}
                                  icon={
                                    beacon.angle_corrected ? "angle" : "warning"
                                  }
                                />
                              )}
                              <p
                                style={{
                                  color: "rgb(102, 102, 102)",
                                  fontFamily: "var(--medium)",
                                }}
                              >
                                {beacon?.main_angle_name}: {mainAngle}°
                              </p>
                            </div>
                            <div className="d-flex mt-1">
                              <Icon
                                className="mr-2"
                                color={"#ffff"}
                                size={20}
                                icon={
                                  beacon.angle_corrected ? "angle" : "warning"
                                }
                              />{" "}
                              <p
                                style={{
                                  color: beacon.angle_corrected
                                    ? Colors.darkest_gray
                                    : Colors.new_orange,
                                }}
                              >
                                {beacon?.second_angle_name}: {secondAngle}°
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p className="modal-mdd-info-text-2">-</p>
                        )}

                        {/* <p
                        className="modal-mdd-info-text"
                        style={{
                          color: beacon.angle_corrected
                            ? Colors.darkest_gray
                            : !beacon.signal_beacon
                            ? Colors.darkest_gray
                            : Colors.new_orange,
                        }}
                      >
                        {beacon.signal_beacon ? `${beacon?.angle}°` : "-"}
                      </p> */}
                      </div>
                    </div>
                  </OverlayTrigger>
                  <div className="modal-mdd-info">
                    <p className="modal-mdd-info-title mb-1">Sensor</p>
                    <div className="d-flex align-items-center">
                      <Icon
                        color={Colors.brand_blue}
                        size={20}
                        icon="preveni-icon"
                      />
                      <p className="modal-mdd-info-text">{beacon?.name}</p>
                      <div className="d-flex align-items-center">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Desvincular</Tooltip>}
                        >
                          <span
                            className="ball-sensor"
                            variant="link"
                            onClick={handleOpenUnlinkModal}
                          >
                            <Icon
                              color={Colors.darkest_gray}
                              size={16}
                              icon="unlink"
                              style={{ alignItems: "center" }}
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {info?.historic?.motive != undefined &&
                info?.historic?.motive != null && (
                  <div className="modal-mdd-last-update my-4">
                    <p className="last-title">Última alteração</p>
                    <p className="last-text mb-2">{info.historic.title}</p>
                    <p className="last-text">{info.historic.motive}</p>
                    <p className="user-update">
                      {moment(info.historic.created_at).format("LLL")}
                    </p>
                  </div>
                )}

              {/* MOTIVO E TEMPO QUANDO AVISO FOR SUSPENSO  */}
              {suspended?.datetime_final != undefined && (
                <>
                  <div className="modal-mdd-info-suspend">
                    <div className="modal-mdd-info mt-4">
                      <p className="modal-mdd-info-title">Tempo da suspensão</p>

                      <div className="d-flex align-items-center">
                        <Icon
                          color={Colors.brand_blue}
                          size={20}
                          icon={"clock-arrow-up"}
                        />

                        {suspended?.minutes !== "INDETERMINATE" ? (
                          <p className="modal-mdd-info-text text-lowercase">
                            {ConvertRuntime(suspended.minutes)}
                          </p>
                        ) : (
                          <p className="modal-mdd-info-text text-capitalize">
                            Indeterminado
                          </p>
                        )}
                      </div>
                    </div>

                    {suspended.time_suspend_left !== null && (
                      <div className="modal-mdd-info modal-mdd-info-time mt-4">
                        <p className="modal-mdd-info-title">
                          Tempo restante da suspensão
                        </p>
                        <div className="d-flex align-items-center">
                          <Icon
                            color={Colors.brand_blue}
                            size={20}
                            icon={"clock"}
                          />

                          <p
                            className="modal-mdd-info-text text-lowercase"
                            style={{
                              marginLeft: "8px",
                            }}
                          >
                            {RenderTimeSuspend()}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <p className="modal-mdd-info-title mb-1">Motivo</p>
                    <p
                      className="modal-mdd-info-text ml-0"
                      style={{
                        textTransform: "none",
                        color: Colors.darkest_gray,
                      }}
                    >
                      {suspended.motive}
                    </p>
                  </div>

                  <div className="w-100 mt-4">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div className="c-profile-dropdown">
                        {renderPhoto({ dados: suspended })}
                      </div>

                      <div className="dados-default">
                        <div className="name-default">
                          Por {suspended.user_name}
                        </div>
                        <div className="date-default">
                          {moment(suspended.datetime_initial).format("LLL")}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* {tag != "realized" && (
              <div className="mt-3">
                <h3
                  className="modal-mdd-realized-title"
                  style={{ color: Colors.darker_gray }}
                >
                  A mudança de decúbito foi realizada?
                </h3>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => setPage(3)}
                    style={{ width: 100 }}
                    className="secondary-btn mr-3"
                  >
                    <p>Não</p>
                  </button>
                  <button
                    onClick={() => setPage(2)}
                    style={{ width: 100 }}
                    className="default-btn m-0"
                  >
                    <p>Sim</p>
                  </button>
                </div>
              </div>
            )} */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              onClick={() => setPage(5)}
              className="modal-footer-btn mr-0 btn-cancel"
            >
              <Icon color={Colors.brand_pink} size={20} icon="user-check" />
              <p
                className="mb-0 ml-2"
                style={{
                  color: Colors.brand_pink,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Dar alta
              </p>
            </button>
          </Col>
          <Col xs={5} md={5} lg={5} className="px-0">
            <button
              disabled={loadingBtn === "flex" || notification_id === null}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  notification_id !== null
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (!isSuspendeded) {
                  setPage(4);
                } else {
                  setPage(1);
                  if (userHasPermission(user)) {
                    Reactivate();
                    return false;
                  }
                  setShowPw(true);
                }
              }}
            >
              <Icon
                color={notification_id !== null ? "white" : Colors.dark_gray}
                size={20}
                icon={isSuspendeded ? "bell" : "bell-off"}
              />
              <p
                className="mb-0 ml-2"
                style={{
                  color: notification_id !== null ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                {isSuspendeded ? "Reativar" : "Suspender"} avisos
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }

  function RenderPage2() {
    return (
      <>
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">Quem realizou a mudança?</div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
          </div>

          <div className="modal-list">
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Row
                className="mt-2 mb-1 modal-text-xs"
                style={{ color: Colors.darkest_gray }}
              >
                Buscar responsável
              </Row>

              <Row className="mb-4">
                <input
                  className="default-input md"
                  placeholder="Digite o nome do responsável"
                  onChange={(v) => setSearch(v.target.value)}
                />
              </Row>
            </div>

            {listUsers.map((item, index) => {
              var found = listUserChooser.find((i) => i.id == item.id);
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => SetarUser(item)}
                >
                  <p
                    style={{
                      color:
                        found != undefined
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <CheckBox
                    checked={found != undefined}
                    value={item}
                    onclick={SetarUser}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div className="secondary-btn" onClick={() => handler_modal(false)}>
              Cancelar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (userHasPermission(user)) {
                  Confirm();
                  return false;
                }
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }

  function RenderPage3() {
    return (
      <>
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">Por que não foi realizada?</div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
          </div>

          <div className="modal-list">
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Row
                className="mt-3 mb-1 modal-text-bold-sm"
                style={{ color: Colors.darkest_gray }}
              >
                Motivo
              </Row>
            </div>

            {listMotives.map((item, index) => {
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => {
                    setOtherMotive("");
                    setMotiveChooser(item);
                  }}
                >
                  <p
                    style={{
                      color:
                        item.id === motiveChooser?.id
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <div className="container-check">
                    <input
                      className={`input-round ${
                        item.id != motiveChooser?.id && "radio-inactive"
                      }`}
                      type="radio"
                      checked={item.id === motiveChooser?.id}
                      onClick={() => {
                        setOtherMotive("");
                        setMotiveChooser(item);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            {motiveChooser.name == "Outros" && (
              <div className="mb-3 px-4">
                <input
                  className={`default-input md ${
                    otherMotiveInvalid ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Digite outro motivo aqui"
                  value={otherMotive}
                  onChange={(e) => handleOtherMotive(e.target.value)}
                />
                {otherMotiveInvalid && (
                  <p className="text-error-red">Digite um motivo válido</p>
                )}
              </div>
            )}

            <hr className="my-0" />
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Row
                className="mt-4 mb-1 modal-text-bold-sm"
                style={{ color: Colors.darkest_gray }}
              >
                Responsável
              </Row>

              <Row
                className="mt-2 mb-1 modal-text-xs"
                style={{ color: Colors.darkest_gray }}
              >
                Buscar responsável
              </Row>

              <Row className="mb-4">
                <input
                  className="default-input md"
                  placeholder="Digite o nome do responsável"
                  onChange={(v) => setSearch(v.target.value)}
                />
              </Row>
            </div>

            {listUsers.map((item, index) => {
              var found = listUserChooser.find((i) => i.id == item.id);
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => SetarUser(item)}
                >
                  <p
                    style={{
                      color:
                        found != undefined
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <CheckBox
                    checked={found != undefined}
                    value={item}
                    onclick={SetarUser}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center  mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div className="secondary-btn" onClick={() => handler_modal(false)}>
              Cancelar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (userHasPermission(user)) {
                  Confirm();
                  return false;
                }
                setShowPw(true);
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }

  function RenderPage4() {
    return (
      <>
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">Suspender avisos</div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
          </div>

          <div className="modal-list">
            {/* <div
              className="d-flex align-items-end"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <div style={{ width: "70%" }}>
                <div
                  className="mt-3 mb-1 modal-text-bold-sm"
                  style={{ color: Colors.darkest_gray }}
                >
                  Suspender durante
                </div>

                <input
                  type="number"
                  disabled={
                    suspendForever ||
                    suspendIndeterminate ||
                    defaultSuspensionTimeIsEnable
                  }
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor:
                      suspendForever ||
                      suspendIndeterminate ||
                      defaultSuspensionTimeIsEnable
                        ? Colors.lightest_gray
                        : "transparent",
                  }}
                  className="default-input md"
                  placeholder="Digite o período"
                  value={timeSuspend}
                  onChange={(v) => setTimeSuspend(v.target.value)}
                />
              </div>

              <div style={{ width: "30%" }}>
                <Dropdown className="mdd-dropdown-time">
                  <Dropdown.Toggle
                    disabled={
                      suspendForever ||
                      suspendIndeterminate ||
                      defaultSuspensionTimeIsEnable
                    }
                    style={{ maxHeiht: 37 }}
                    className="w-100"
                    id="dropdown-mdd-time"
                  >
                    {typeTimeSuspend.label}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    rootCloseEvent={"click"}
                    className="div-drop-sort"
                  >
                    {type_time.map((item) => {
                      return (
                        <Dropdown.Item
                          style={{
                            color:
                              item.value == typeTimeSuspend.value
                                ? Colors.brand_blue
                                : Colors.dark_gray,
                          }}
                          onClick={() => setTypeTimeSuspend(item)}
                        >
                          <Icon
                            icon={item.icon}
                            size={24}
                            color={
                              item.value == typeTimeSuspend.value
                                ? Colors.brand_blue
                                : Colors.dark_gray
                            }
                            className="mr-3"
                          />
                          {item.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {false && (
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center px-3 mt-3 mb-3"
                onClick={() => {
                  setTimeSuspend("INDETERMINATE");
                  setSuspendForever(false);
                  setSuspendIndeterminate(!suspendIndeterminate);
                }}
              >
                <CheckBox
                  checked={suspendIndeterminate}
                  value={suspendIndeterminate}
                  onclick={setSuspendIndeterminate}
                />
                <p className="modal-name mb-0" style={{ fontSize: 14 }}>
                  Suspender por tempo indeterminado
                </p>
              </div>
            )}
*/}
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Row
                className="mt-4 mb-2 modal-text-bold"
                style={{ color: Colors.darkest_gray }}
              >
                Motivo
              </Row>
            </div>

            {listMotives.map((item, index) => {
              return (
                <>
                  <div
                    style={{ paddingLeft: "26px" }}
                    key={index}
                    className="modal-item modal-item-edit"
                    onClick={() => handleMotiveSelection(item)}
                  >
                    <p
                      style={{
                        color:
                          item.id === motiveChooser?.id
                            ? Colors.brand_blue
                            : Colors.darkest_gray,
                      }}
                      className="modal-name mb-0"
                    >
                      {item.name}
                    </p>

                    <div className="container-check">
                      <input
                        className={`input-round ${
                          item.id != motiveChooser?.id && "radio-inactive"
                        }`}
                        type="radio"
                        checked={item.id === motiveChooser?.id}
                        onChange={() => handleMotiveSelection(item)}
                      />
                    </div>
                  </div>
                  {isMotiveSelected &&
                    motiveChooser.id == item.id &&
                    itemsWithPause.includes(item.name) && (
                      <div
                        className="d-flex"
                        style={{
                          padding: "12px 18px",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontFamily: "Rubik-Medium",
                              fontSize: "14px",
                            }}
                          >
                            Deseja pausar o tempo de monitoramento?
                          </p>
                          <span
                            style={{
                              fontFamily: "Rubik-Regular",
                              fontSize: "12px",
                              color: "#999999",
                            }}
                          >
                            O sensor registrará a posição, mas não contará o
                            tempo de decúbito até que seja reativado.
                          </span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            style={{
                              background:
                                isPaused == 1
                                  ? Colors.brand_blue
                                  : Colors.lightest_gray,
                              height: 50,
                            }}
                            className="btn-click"
                            onClick={() => handleMotiveSelection(item, true)}
                          >
                            <p
                              className="mb-0"
                              style={{
                                color:
                                  isPaused == 1 ? "white" : Colors.darker_gray,
                              }}
                            >
                              Sim
                            </p>
                          </button>

                          <button
                            style={{
                              background:
                                isPaused == 0
                                  ? Colors.brand_blue
                                  : Colors.lightest_gray,
                              height: 50,
                            }}
                            className="btn-click"
                            onClick={() => handleMotiveSelection(item, false)}
                          >
                            <p
                              className="mb-0"
                              style={{
                                color:
                                  isPaused == 0 ? "white" : Colors.darker_gray,
                              }}
                            >
                              Não
                            </p>
                          </button>
                        </div>
                      </div>
                    )}
                </>
              );
            })}

            {motiveChooser.name == "Outros" && (
              <div className="mb-3 px-4">
                <input
                  className={`default-input md ${
                    otherMotiveInvalid ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Digite outro motivo aqui"
                  value={otherMotive}
                  onChange={(e) => {
                    handleOtherMotive(e.target.value);
                    if (e.target.value != null) {
                      handleMotiveSelection(motiveChooser);
                    }
                  }}
                />
                {otherMotiveInvalid && (
                  <p className="text-error-red">Digite um motivo válido</p>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        {/* <div className="mt-4" style={{ paddingLeft: 20, paddingRight: 20 }}>
              <p className="mt-4 mb-1 modal-text-bold-sm">Lesões</p>
              <div className="d-flex">
                <button
                  style={{
                    background:
                      hasWound == true
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setHasWound(true)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: hasWound == true ? "white" : Colors.darker_gray,
                    }}
                  >
                    Com lesão
                  </p>
                </button>
                <button
                  style={{
                    background:
                      hasWound == false
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => {
                    setWounds([{ wound_id: 0, stage_id: 0 }]);
                    setHasWound(false);
                  }}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: hasWound == false ? "white" : Colors.darker_gray,
                    }}
                  >
                    {" "}
                    Sem lesão
                  </p>
                </button>
              </div>

              {hasWound == true && (
                <div className="section-discharge mt-2">
                  {wounds.map((item, index) => {
                    return (
                      <Row key={index} className="mb-3">
                        <Col className="mb-3 mb-md-0" col={12} md={5}>
                          <Form.Label className="field-label">
                            Local da lesão
                          </Form.Label>
                          <Select
                  captureMenuScroll={false}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={listWounds}
                            isClearable={false}
                            onChange={(item) => {
                              var newList = wounds.map((it, ind) => {
                                if (ind == index) {
                                  it.stage_id = item.value;
                                }
                                return it;
                              });
                              setWounds(newList);
                            }}
                          />
                        </Col>

                        <Col col={12} md={5}>
                          <Form.Label className="field-label">
                            Estágio da lesão
                          </Form.Label>
                          <Select
                  captureMenuScroll={false}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={listStages}
                            isClearable={false}
                            onChange={(item) => {
                              var newList = wounds.map((it, ind) => {
                                if (ind == index) {
                                  it.wound_id = item.value;
                                }
                                return it;
                              });
                              setWounds(newList);
                            }}
                          />
                        </Col>

                        <Col col={12} md={2}>
                          <button
                            title="Remover local"
                            className="btn-remove w-100"
                            disabled={index == 0 ? true : false}
                            onClick={() => {
                              var newList = wounds.filter((c, d) => {
                                if (index != d) {
                                  return c;
                                }
                              });
                              setWounds(newList);
                            }}
                          >
                            <Icon
                              color={
                                index == 0
                                  ? Colors.light_gray
                                  : Colors.darkest_gray
                              }
                              size={15}
                              icon="minus"
                            />
                          </button>
                        </Col>
                      </Row>
                    );
                  })}

                  <button
                    className="d-flex align-items-center mt-3 reset-button"
                    onClick={() =>
                      setWounds([...wounds, { wound_id: 0, stage_id: 0 }])
                    }
                  >
                    <Icon
                      color={Colors.brand_pink}
                      size={15}
                      icon="plus"
                      className="mr-2"
                    />
                    <p className="mb-0 add-other-text">Adicionar outra lesão</p>
                  </button>
                </div>
              )}
            </div> */}

        {/* BUSCAR RESPONSÁVEL SUSPENDER AVISOS  */}
        {/* {user.dados.permission_id > 3 && (
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  className="mt-4 mb-1 modal-text-bold-sm"
                  style={{ color: Colors.darkest_gray }}
                >
                  Responsável
                </Row>

                <Row
                  className="mt-2 mb-1 modal-text-xs"
                  style={{ color: Colors.darkest_gray }}
                >
                  Buscar responsável
                </Row>

                <Row className="mb-4">
                  <input
                    className="default-input md"
                    placeholder="Digite o nome do responsável"
                    onChange={(v) => {
                      setSearch(v.target.value);
                    }}
                  />
                </Row>
              </div>
            )}

            {listUsers.map((item, index) => {
              var found = listUserChooser.find((i) => i.id == item.id);
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => SetarUser(item)}
                >
                  <p
                    style={{
                      color:
                        found != undefined
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <CheckBox
                    checked={found != undefined}
                    value={item}
                    onclick={SetarUser}
                  />
                </div>
              );
            })} */}
        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div className="secondary-btn" onClick={() => handler_modal(false)}>
              Cancelar
            </div>
          </Col>
          <Col xs={6} md={5} className="px-0">
            <button
              disabled={!isMotiveSelected || loadingBtn === "flex"}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  isMotiveSelected &&
                  (itemsWithPause.includes(motiveChooser.name)
                    ? isPaused != null
                    : true)
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (isMotiveSelected && !defaultSuspensionTimeIsEnable) {
                  setPage(6);
                } else if (isMotiveSelected && defaultSuspensionTimeIsEnable) {
                  checkIfUserHasPermissionAndSuspend();
                }
              }}
            >
              <p
                className="mb-0"
                style={{
                  color:
                    isMotiveSelected &&
                    (itemsWithPause.includes(motiveChooser.name)
                      ? isPaused != null
                      : true)
                      ? "white"
                      : Colors.dark_gray,
                  display: loadingBtn === "none" ? "flex" : "none",
                }}
              >
                Suspender avisos
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }

  function RenderPage5() {
    return (
      <>
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">Dar alta</div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
            <Row
              className="mt-3 patient-info"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <p className="mb-1">Paciente</p>
                <div className="modal-title mb-1" id="patient-name">
                  {patient_name}
                </div>
                <p className="mb-2" id="room-name">
                  {room == null && subroom}
                  {room != null && room + " | " + subroom}
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title"></div>
              </Col>
            </Row>
          </div>

          <div className="modal-list">
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Row
                className="mt-3 mb-1 modal-text-bold-sm"
                style={{ color: Colors.darkest_gray }}
              >
                Motivo
              </Row>
            </div>

            {listMotives.map((item, index) => {
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => {
                    setSuspendForever(true);
                    setOtherMotive("");
                    setMotiveChooser(item);
                  }}
                >
                  <p
                    style={{
                      color:
                        item.id === motiveChooser?.id
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <div className="container-check">
                    <input
                      className={`input-round ${
                        item.id != motiveChooser?.id && "radio-inactive"
                      }`}
                      type="radio"
                      checked={item.id === motiveChooser?.id}
                      onClick={() => {
                        setSuspendForever(true);
                        setOtherMotive("");
                        setMotiveChooser(item);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            {motiveChooser.name == "Outros" && (
              <div className="mb-3 px-4">
                <input
                  className={`default-input md ${
                    otherMotiveInvalid ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Digite outro motivo aqui"
                  value={otherMotive}
                  onChange={(e) => handleOtherMotive(e.target.value)}
                />
                {otherMotiveInvalid && (
                  <p className="text-error-red">Digite um motivo válido</p>
                )}
              </div>
            )}

            {/* <div className="mt-4" style={{ paddingLeft: 20, paddingRight: 20 }}>
              <p className="mt-4 mb-1 modal-text-bold-sm">Lesões</p>
              <div className="d-flex">
                <button
                  style={{
                    background:
                      hasWound == true
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setHasWound(true)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: hasWound == true ? "white" : Colors.darker_gray,
                    }}
                  >
                    Com lesão
                  </p>
                </button>
                <button
                  style={{
                    background:
                      hasWound == false
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => {
                    setWounds([{ wound_id: 0, stage_id: 0 }]);
                    setHasWound(false);
                  }}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: hasWound == false ? "white" : Colors.darker_gray,
                    }}
                  >
                    {" "}
                    Sem lesão
                  </p>
                </button>
              </div>

              {hasWound == true && (
                <div className="section-discharge mt-2">
                  {wounds.map((item, index) => {
                    return (
                      <Row key={index} className="mb-3">
                        <Col className="mb-3 mb-md-0" col={12} md={5}>
                          <Form.Label className="field-label">
                            Local da lesão
                          </Form.Label>
                          <Select
                  captureMenuScroll={false}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={listWounds}
                            isClearable={false}
                            onChange={(item) => {
                              var newList = wounds.map((it, ind) => {
                                if (ind == index) {
                                  it.stage_id = item.value;
                                }
                                return it;
                              });
                              setWounds(newList);
                            }}
                          />
                        </Col>

                        <Col col={12} md={5}>
                          <Form.Label className="field-label">
                            Estágio da lesão
                          </Form.Label>
                          <Select
                  captureMenuScroll={false}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={listStages}
                            isClearable={false}
                            onChange={(item) => {
                              var newList = wounds.map((it, ind) => {
                                if (ind == index) {
                                  it.wound_id = item.value;
                                }
                                return it;
                              });
                              setWounds(newList);
                            }}
                          />
                        </Col>

                        <Col col={12} md={2}>
                          <button
                            title="Remover local"
                            className="btn-remove w-100"
                            disabled={index == 0 ? true : false}
                            onClick={() => {
                              var newList = wounds.filter((c, d) => {
                                if (index != d) {
                                  return c;
                                }
                              });
                              setWounds(newList);
                            }}
                          >
                            <Icon
                              color={
                                index == 0
                                  ? Colors.light_gray
                                  : Colors.darkest_gray
                              }
                              size={15}
                              icon="minus"
                            />
                          </button>
                        </Col>
                      </Row>
                    );
                  })}

                  <button
                    className="d-flex align-items-center mt-3 reset-button"
                    onClick={() =>
                      setWounds([...wounds, { wound_id: 0, stage_id: 0 }])
                    }
                  >
                    <Icon
                      color={Colors.brand_pink}
                      size={15}
                      icon="plus"
                      className="mr-2"
                    />
                    <p className="mb-0 add-other-text">Adicionar outra lesão</p>
                  </button>
                </div>
              )}
            </div> */}

            {/* BUSCAR RESPONSÁVEL SUSPENDER AVISOS  */}
            {/* {user.dados.permission_id > 3 && (
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  className="mt-4 mb-1 modal-text-bold-sm"
                  style={{ color: Colors.darkest_gray }}
                >
                  Responsável
                </Row>

                <Row
                  className="mt-2 mb-1 modal-text-xs"
                  style={{ color: Colors.darkest_gray }}
                >
                  Buscar responsável
                </Row>

                <Row className="mb-4">
                  <input
                    className="default-input md"
                    placeholder="Digite o nome do responsável"
                    onChange={(v) => {
                      setSearch(v.target.value);
                    }}
                  />
                </Row>
              </div>
            )}

            {listUsers.map((item, index) => {
              var found = listUserChooser.find((i) => i.id == item.id);
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => SetarUser(item)}
                >
                  <p
                    style={{
                      color:
                        found != undefined
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <CheckBox
                    checked={found != undefined}
                    value={item}
                    onclick={SetarUser}
                  />
                </div>
              );
            })} */}
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div
              className="secondary-btn"
              style={{
                fontSize: "100%",
              }}
              onClick={() => handler_modal(false)}
            >
              Cancelar
            </div>
          </Col>
          <Col xs={6} md={5} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                checkIfUserHasPermissionAndSuspend();
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }

  function RenderPage6() {
    const times = motiveTimes[motiveChooser?.name] || motiveTimes["Outros"];

    return (
      <>
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">Suspender avisos</div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="modal-list-suspension"
            style={{ paddingLeft: 40, paddingRight: 40 }}
          >
            <Row
              className="mt-3 mb-2 modal-name"
              style={{ color: Colors.darkest_gray }}
            >
              Motivo
            </Row>

            <Row className="change-suspension">
              <p className="modal-name mb-0">
                {motiveChooser?.name == "Outros"
                  ? otherMotive
                  : motiveChooser?.name}
              </p>
              <Button
                style={{ color: Colors.brand_pink }}
                variant="link"
                onClick={() => setPage(4)}
              >
                Alterar
              </Button>
            </Row>

            <Row
              className="mt-3 mb-2 modal-name"
              style={{ color: Colors.darkest_gray }}
            >
              Suspender durante
            </Row>
            <Row className="d-flex modal-name">
              {times.map((time, index) => (
                <button
                  className={`btn-time-suspension ${
                    timeSuspend === time ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => handleTimeSelection(time)}
                >
                  {time >= 60
                    ? `${Math.floor(time / 60)}h ${
                        time % 60 ? `${time % 60}min` : ""
                      }`
                    : `${time}min`}
                </button>
              ))}
            </Row>
            <Row>
              <Button
                onClick={toggleSuspensionTime}
                style={{ color: Colors.brand_pink, paddingLeft: "0px" }}
                variant="link"
              >
                Tempo personalizado
                <Icon
                  type="toggle"
                  color={Colors.brand_pink}
                  size={18}
                  icon="arrow-down"
                />
              </Button>
            </Row>
            {suspensionTimeVisible && (
              <Row
                className="d-flex align-items-end card-time-suspension"
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <div style={{ width: "70%" }}>
                  <div
                    className="mt-3 mb-1 modal-name"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Definir tempo personalizado
                  </div>

                  <input
                    type="number"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      backgroundColor: "write",
                    }}
                    className="default-input modal-name"
                    placeholder="Digite o período"
                    value={timeSuspend}
                    onChange={(v) => {
                      let value = v.target.value;
                      setTimeSuspend(value <= 0 ? "" : value);
                    }}
                  />
                </div>

                <div style={{ width: "30%" }}>
                  <Dropdown className="dropdown-mdd-time">
                    <Dropdown.Toggle
                      disabled={
                        suspendForever ||
                        suspendIndeterminate ||
                        defaultSuspensionTimeIsEnable
                      }
                      style={{ maxHeiht: 49 }}
                      className="w-100"
                      id="dropdown-mdd-time-suspension"
                    >
                      {typeTimeSuspend.label}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{ width: "30%" }}
                      rootCloseEvent={"click"}
                      className="div-drop-sort"
                    >
                      {type_time.map((item) => {
                        return (
                          <Dropdown.Item
                            style={{
                              color:
                                item.value == typeTimeSuspend.value
                                  ? Colors.brand_blue
                                  : Colors.dark_gray,
                            }}
                            onClick={() => setTypeTimeSuspend(item)}
                          >
                            <Icon
                              icon={item.icon}
                              size={24}
                              color={
                                item.value == typeTimeSuspend.value
                                  ? Colors.brand_blue
                                  : Colors.dark_gray
                              }
                              className="mr-3"
                            />
                            {item.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Row>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <button
              className="mr-2 btn-cancel"
              onClick={() => handler_modal(false)}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={6} md={5} className="px-0">
            <button
              disabled={!timeSuspend || loadingBtn === "flex"}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor: timeSuspend
                  ? Colors.brand_pink
                  : Colors.lighter_gray,
              }}
              onClick={() => {
                if (timeSuspend) {
                  checkIfUserHasPermissionAndSuspend();
                }
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: timeSuspend ? "white" : Colors.dark_gray,
                  display: loadingBtn === "none" ? "flex" : "none",
                }}
              >
                Suspender avisos
              </p>
              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }

  useEffect(() => {
    if (!patient_id) return;

    const fetchData = () => {
      api
        .get(`/notifications/beacons/mdd`, {
          params: {
            patient_id: patient_id,
            unit_id: unit.selecionado.id,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.length > 0) {
            let patientData = null;
            response.data.forEach((room) => {
              if (room.patient_id === patient_id) {
                patientData = room;
              } else if (room.subrooms && room.subrooms.length > 0) {
                room.subrooms.forEach((subroom) => {
                  if (subroom.patient_id === patient_id) {
                    patientData = subroom;
                  }
                });
              }
            });

            if (patientData?.notification?.beacon) {
              const beaconData = patientData.notification.beacon;
              setMainAngle(beaconData.main_angle);
              setSecondAngle(beaconData.second_angle);
              setPosition_actual_local(beaconData.position_actual);
              setPosition_to_local(beaconData.position_name);
              setTag_local(patientData.notification.tag);
              setTag_color_local(patientData.notification.color);

              setBeacon((prev) => ({
                ...prev,
                angle: beaconData.angle,
                angle_corrected: beaconData.angle_corrected,
                position_actual: beaconData.position_actual,
                position_color: beaconData.position_color,
                position_corrected: beaconData.position_corrected,
                signal_beacon: beaconData.signal_beacon,
                main_angle: beaconData.main_angle,
                second_angle: beaconData.second_angle,
                main_angle_name: beaconData.main_angle_name,
                second_angle_name: beaconData.second_angle_name,
              }));
            }
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar dados do beacon:", error);
        });
    };

    fetchData();
    const interval = setInterval(fetchData, 1000);

    return () => clearInterval(interval);
  }, [patient_id, unit.selecionado.id]);

  return (
    <>
      <Modal
        show={showModalMdd}
        onHide={() => handler_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {showPw && (
          <VerifyPwWithUser
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_request={
              page == 1
                ? Reactivate
                : page == 4 || page == 6 || page == 5
                ? Suspend
                : Confirm
            }
          />
        )}
        {page == 1 && RenderPage1()} {/* default */}
        {page == 2 && RenderPage2()} {/* Sim */}
        {page == 3 && RenderPage3()} {/* Não */}
        {page == 4 && RenderPage4()} {/* Suspender avisos */}
        {page == 5 && RenderPage5()} {/* Dar Alta */}
        {page == 6 && RenderPage6()} {/* Selecionar tempo de suspensão */}
      </Modal>
      <ModalUnlinkSensor
        show_modal={showUnlinkModal}
        handler_modal={setShowUnlinkModal}
        handler_father_modal={handler_modal}
        handler_modal_and_mdd_modal={handleCloseUnlinkModal}
        patient_name={patient_name}
        patient_id={patient_id}
        sensor={beacon?.name}
        unlinkSensor={unlinkSensor}
        reload={reload}
        handler_reload={handler_reload}
      />
    </>
  );
}

export default ModalMdd;
